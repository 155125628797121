.slick-prev {
	left: -17px;
}
.slick-next {
	right: -15px;
}

.slick-next::before,.slick-prev::before{
    color: #9650FA;

}